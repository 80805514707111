<template>
  <div class="title">
    <h2>
      {{titleChinese}}
      <p>{{titleEnglish}}</p>
    </h2>
  </div>
</template>

<script>
export default {
  props: ["titleChinese","titleEnglish"],
};
</script>

<style lang="scss" scoped>
.title {
  h2 {
    text-align: center;
    color: #333333;
    font-size: 36px;
    margin-bottom: 64px;
    margin-top: 0;
    p {
      font-size: 16px;
      font-weight: 400;
      color: #999999;
      margin: 0;
    }
  }
}
</style>